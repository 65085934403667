import { createReducer, on } from '@ngrx/store';
import { updateHeaderTitleAction } from './application.actions';

export const applicationFeatureKey = 'application';

export interface ApplicationState {
  headerTitle: string;
  hasGoBack: boolean;
}

export const initialState: ApplicationState = {
  headerTitle: `<span>B.Comp</span>
    <span class="product-description">
      – the B.Braun drug compatibility database for products, materials and IV solutions
    </span>`,
  hasGoBack: false
};

export const applicationReducer = createReducer<ApplicationState>(
  initialState,
  on(
    updateHeaderTitleAction.updateHeader,
    (state: ApplicationState, data: { title: string; hasGoBack: boolean }) => ({
      ...state,
      headerTitle: data.title,
      hasGoBack: data.hasGoBack
    })
  )
);
