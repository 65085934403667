<cx-navigation-drawer>
  @if (user.admin) {
    <cx-navigation-drawer-headline>{{ text.MENU.ADMINISTRATION }}</cx-navigation-drawer-headline>
    @for (item of menuItemsAdmin; track $index) {
      <cx-navigation-drawer-button
        [name]="item.name"
        [routerLink]="item.link"
        [routerLinkActiveOptions]="{ exact: false }"
      />
    }
    <mat-divider></mat-divider>
  }
  @if (user.user) {
    <cx-navigation-drawer-headline>{{ text.MENU.USER }}</cx-navigation-drawer-headline>
    @for (item of menuItemsUser; track $index) {
      <cx-navigation-drawer-button
        [name]="item.name"
        [routerLink]="item.link"
        [routerLinkActiveOptions]="{ exact: false }"
      />
    }
    <mat-divider></mat-divider>
  }

  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <cx-navigation-drawer-button [name]="text.MENU.LEGAL_TERMS" />
    </mat-expansion-panel-header>
    @for (item of menuItemsLegal; track $index) {
      <cx-navigation-drawer-button
        [name]="item.name"
        [routerLink]="item.link"
        [routerLinkActiveOptions]="{ exact: false }"
      />
    }
  </mat-expansion-panel>
</cx-navigation-drawer>
<cx-navigation-drawer-container>
  <ng-content></ng-content>
</cx-navigation-drawer-container>
