import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { CxNavigationDrawerModule } from '@bbraun/cortex/navigation-drawer';
import { ROUTES } from '@shared/constants/routes/routes.constants';
import { TEXT } from '@shared/constants/text/text.constants';
import { UserInfo } from '@shared/interfaces/user/user-info.interface';
import { UserFacade } from '@store/user/user.facade';

@Component({
  selector: 'app-menu',
  standalone: true,
  imports: [CommonModule, CxNavigationDrawerModule, MatDividerModule, MatExpansionModule],
  templateUrl: './menu.component.html',
  styleUrl: './menu.component.sass'
})
export class MenuComponent implements OnInit {
  user!: UserInfo;

  protected readonly text = TEXT;
  protected readonly routes = ROUTES;

  protected readonly menuItemsAdmin = [
    {
      name: this.text.MENU.DRUG_CATEGORIES,
      link: this.createRouterLink([
        this.routes.ADMINISTRATION,
        this.routes.ENTITIES,
        this.routes.DRUG_CATEGORIES
      ])
    },
    {
      name: this.text.MENU.DRUGS,
      link: this.createRouterLink([
        this.routes.ADMINISTRATION,
        this.routes.ENTITIES,
        this.routes.DRUGS
      ])
    },
    {
      name: this.text.MENU.CARRIER_SOLUTIONS,
      link: this.createRouterLink([
        this.routes.ADMINISTRATION,
        this.routes.ENTITIES,
        this.routes.CARRIER_SOLUTIONS
      ])
    },
    {
      name: this.text.MENU.PRODUCTS,
      link: this.createRouterLink([
        this.routes.ADMINISTRATION,
        this.routes.ENTITIES,
        this.routes.PRODUCTS
      ])
    },
    {
      name: this.text.MENU.COMBINATIONS,
      link: this.createRouterLink([this.routes.ADMINISTRATION, this.routes.COMBINATIONS])
    }
  ];

  protected readonly menuItemsUser = [
    {
      name: this.text.MENU.COMBINATIONS,
      link: this.createRouterLink([this.routes.COMBINATIONS])
    },
    {
      name: this.text.MENU.GUIDE,
      link: this.createRouterLink([this.routes.GUIDE])
    },
    {
      name: this.text.MENU.ABBREVIATIONS,
      link: this.createRouterLink([this.routes.ABBREVIATIONS])
    }
  ];

  protected readonly menuItemsLegal = [
    {
      name: this.text.MENU.PRIVACY_POLICY,
      link: this.createRouterLink([this.routes.LEGAL, this.routes.PRIVACY])
    },
    {
      name: this.text.MENU.TERMS_OF_USE,
      link: this.createRouterLink([this.routes.LEGAL, this.routes.TERMS_OF_USE])
    },
    {
      name: this.text.MENU.IMPRINT,
      link: this.createRouterLink([this.routes.LEGAL, this.routes.IMPRINT])
    }
  ];

  private readonly userFacade = inject(UserFacade);

  ngOnInit(): void {
    this.userFacade.user$.subscribe((user) => {
      this.user = user;
    });
  }

  private createRouterLink(path: Array<string>): string {
    return path.join('/');
  }
}
