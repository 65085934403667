<ng-container *ngIf="userFacade.user$ | async as user">
  <cx-header [navigationDrawerMode]="true">
    <cx-header-title>
      <h1 [innerHTML]="headerTitle$ | async | stage"></h1>
    </cx-header-title>
    <cx-header-back-navigator
      [ngClass]="{ 'cx-header-back-navigator--hidden': !(hasGoBack$ | async) }"
      (click)="goBack()"
    >
      Back
    </cx-header-back-navigator>
  </cx-header>
  <app-system-notifications *ngIf="user?.user && !user?.error"></app-system-notifications>
  <app-menu *ngIf="user?.user && !user?.error">
    <router-outlet></router-outlet>
  </app-menu>
</ng-container>
