import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '@environment/environment';

@Pipe({
  name: 'stage',
  standalone: true
})
export class StagePipe implements PipeTransform {
  transform(value: string | null): string {
    if (!value) {
      return '';
    }
    return this.isProd() ? value : this.addStage(value);
  }

  private isProd(): boolean {
    return environment.production;
  }

  private addStage(value: string): string {
    return `${value} <span class="red">${environment.environment}<span>`;
  }
}
