import { Component } from '@angular/core';

@Component({
  selector: 'app-entry-point',
  standalone: true,
  imports: [],
  templateUrl: './entry-point.component.html',
  styleUrl: './entry-point.component.sass'
})
export class EntryPointComponent {

}
