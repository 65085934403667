import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '..';
import { updateHeaderTitleAction } from './application.actions';
import * as applicationSelectors from './application.selectors';

@Injectable()
export class ApplicationFacade {
  readonly headerTitle$ = this.store.select(applicationSelectors.getHeaderTitle);
  readonly hasGoBack$ = this.store.select(applicationSelectors.getHasGoBack);

  constructor(private store: Store<State>) {}

  public setHeader(header: { title: string; hasGoBack: boolean }): void {
    this.store.dispatch(updateHeaderTitleAction.updateHeader(header));
  }
}
