import { createAction, createActionGroup, props } from '@ngrx/store';

export const genericErrorAction = createAction(
  '[APP] GENERIC_ERROR',
  props<{ message?: string }>()
);

export const updateHeaderTitleAction = createActionGroup({
  source: '[ APP ]',
  events: {
    'update header': props<{ title: string; hasGoBack: boolean }>()
  }
});
